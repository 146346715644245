import swal from 'sweetalert2';
import Utilities from '../../Utilities';
import CampaignEditUtilities from './CampaignEditUtilities';

const businessID = Utilities.getUrlParameter('business');
const campaignID = Utilities.getUrlParameter('campaign_id');
const projectID = Utilities.getUrlParameter('project');

/**
 *
 * FUNCTIONS
 *
 */

/**
 * Update the dropdown with new options using the supplied object.
 *
 * @param objectToUse
 * @param selectID
 */
function updateDropDownOptions(objectToUse, selectID) {
  let html = '<option value="">Select One</option>';
  const objectKeys = Object.keys(objectToUse);

  if (objectKeys.length > 0) {
    objectKeys.forEach((key) => {
      const { fb_id: fbID, name } = objectToUse[key];
      html += `<option value="${fbID}">${name}</option>`;
    });
  }

  $(selectID).each(function processSelects() {
    $(this).html(html).prop('disabled', false).selectpicker('refresh');
    $('#essentials-form').trigger('reinitialize.areYouSure');
  });
}

/**
 * Pull IG Accounts for Ad Account and Page.
 *
 * @param adAccountID
 * @param pageID
 * @param type
 */
function pullIGAccountsForAdAccountAndPage(adAccountID, pageID, type) {
  let selectID = 'ig_secondary_account_id';

  if (type === 'primary') {
    selectID = 'ig_primary_account_id';
  }

  const $dropdown = $(`select#${selectID}`);
  const $dropdownSpinner = $(`label[for="${selectID}"] .ig-spinner`);

  $dropdown.prop('disabled', true);
  $dropdownSpinner.removeClass('d-none');

  $.ajax({ url: `/facebook/business-assets/get/process?ad_account_id=${adAccountID}&type=instagram&page_id=${pageID}`, type: 'get', dataType: 'json' })
    .done((data) => {
      let newData = {};

      if (data.success) {
        const { assets } = data;
        newData = assets;
      } else {
        console.log('Failed to fetch IG Accounts for the ad account and FB Page.');
      }

      if (type === 'primary') {
        updateDropDownOptions(newData, 'select#ig_primary_account_id');
      } else {
        updateDropDownOptions(newData, 'select#ig_secondary_account_id');
      }

      $dropdownSpinner.addClass('d-none');
    })
    .fail((jqXHR, status, error) => {
      swal.fire({
        icon: 'error',
        title: error,
        timer: 3000,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      }).then(() => {
        if (type === 'primary') {
          updateDropDownOptions({}, 'select#ig_primary_account_id');
        } else {
          updateDropDownOptions({}, 'select#ig_secondary_account_id');
        }

        $dropdownSpinner.addClass('d-none');

        $('#loading-overlay').hide();
      });
    });
}

/**
 * Pull catalogs for Ad Account.
 * @param adAccountID
 */
function pullCatalogsForAdAccount(adAccountID) {
  $.ajax({ url: `/facebook/catalogs/get/process?ad_account_id=${adAccountID}`, type: 'get', dataType: 'json' })
    .done((data) => {
      if (data.success) {
        const { catalogs } = data;
        window.catalogsForAdAccount = catalogs;
      } else {
        window.catalogsForAdAccount = {};
        console.log('Failed to fetch FB Catalogs for the ad account.');
      }

      CampaignEditUtilities.updateCatalogDropDowns();
    })
    .fail((jqXHR, status, error) => {
      swal.fire({
        icon: 'error',
        title: error,
        timer: 3000,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      }).then(() => {
        window.catalogsForAdAccount = {};

        CampaignEditUtilities.updateCatalogDropDowns();
        $('#loading-overlay').hide();
      });
    });
}

/**
 * Pull pages for Ad Account.
 *
 * @param adAccountID
 */
function pullPagesForAdAccount(adAccountID) {
  $.ajax({ url: `/facebook/business-assets/get/process?ad_account_id=${adAccountID}&type=page`, type: 'get', dataType: 'json' })
    .done((data) => {
      if (data.success) {
        const { assets } = data;
        updateDropDownOptions(assets, 'select.fbPageDropdown');
      } else {
        updateDropDownOptions({}, 'select.fbPageDropdown');
        console.log('Failed to fetch FB Pages for the ad account.');
      }
    })
    .fail((jqXHR, status, error) => {
      swal.fire({
        icon: 'error',
        title: error,
        timer: 3000,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      }).then(() => {
        updateDropDownOptions({}, 'select.fbPageDropdown');
        $('#loading-overlay').hide();
      });
    });
}

export const init = () => {
  $(() => {
    /**
     *
     * INITIAL LOAD EVENTS
     *
     */

    $('#essentials-form').areYouSure();
    // $('#goals-form').areYouSure();

    /** INITIAL LOAD EVENTS -- END */

    /**
     *
     * EVENT TRIGGER HANDLERS
     *
     */

    $('#campaign_type').on('change', (event) => {
      if ($(event.currentTarget).val() === 1) {
        $('.google-option,.microsoft-option').hide();
        $('.google-option-input, .microsoft-option-input').prop('disabled', true);
        $('.fb-option-input').prop('disabled', false);
        $('.fb-option').show();
      } else if ($(event.currentTarget).val() === 2) {
        $('.fb-option,.microsoft-option').hide();
        $('.fb-option-input, .microsoft-option-input').prop('disabled', true);
        $('.google-option-input').prop('disabled', false);
        $('.google-option').show();
      } else if ($(event.currentTarget).val() === 3) {
        $('.fb-option, .google-option').hide();
        $('.fb-option-input, .google-option-input').prop('disabled', true);
        $('.microsoft-option-input').prop('disabled', false);
        $('.microsoft-option').show();
      }
    });

    // Update the catalogs when the facebook account changes
    $('#facebook_account_id').on('change', (event) => {
      const adAccountID = $(event.currentTarget).val();

      if (adAccountID.length > 4) {
        pullCatalogsForAdAccount(adAccountID);
        pullPagesForAdAccount(adAccountID);
      }
    });

    // Update the ig account dropdown when the facebook page changes
    $('select#fb_primary_page_id').on('change', (event) => {
      const adAccountID = $('#facebook_account_id').val();
      const pageID = $(event.currentTarget).val();

      if (adAccountID.length > 4 && pageID.length > 0) {
        pullIGAccountsForAdAccountAndPage(adAccountID, pageID, 'primary');
      }
    });

    // Update the ig account dropdown when the facebook page changes
    $('select#fb_secondary_page_id').on('change', (event) => {
      const adAccountID = $('#facebook_account_id').val();
      const pageID = $(event.currentTarget).val();

      if (adAccountID.length > 4 && pageID.length > 0) {
        pullIGAccountsForAdAccountAndPage(adAccountID, pageID, 'secondary');
      }
    });

    $('#essentials-form').on('submit', (event) => {
      event.preventDefault();
      $('#essentials-error-alert').hide();
      $('#essentials-submit').addClass('btn-waiting').prop('disabled', true);
      $.ajax({
        url: `/campaign/edit/process?project_id=${projectID}&campaign=${campaignID}`, type: 'get', data: $(event.currentTarget).serialize(), dataType: 'json',
      })
        .done((data) => {
          if (data.success) {
            $('#essentials-warning-alert').hide();

            $('#essentials-submit').removeClass('btn-waiting').prop('disabled', false);
            $('#essentials-success-alert').fadeTo(2000, 500).slideDown(100, () => {
              $('#essentials-success-alert').slideUp(500);
            });
            $('#page-title-campaign').html($('#name').val());
            $('#campaign-name').html($('#name').val());
            $('#client-name').html($('#client_id option:selected').text());
            $('#client-link').attr('href', `/business/edit?business_id=${$('#client_id option:selected').val()}${window.window.location.search}`);
            // $('#client-link').attr('href', '/business/edit?business_id=' + $('#client_id option:selected').val());

            // If we have a catalog selected then we need to lock things down
            const currentCatalogForCampaign = $('#essentials-form #fb_catalog_id').val();

            if (currentCatalogForCampaign != null && currentCatalogForCampaign !== '') {
              CampaignEditUtilities.lockCatalogDropDowns(currentCatalogForCampaign);
            }
          } else {
            $('#essentials-error-body').html(data.message);
            $('#essentials-error-alert').slideDown(100);
            $('#essentials-submit').removeClass('btn-waiting').prop('disabled', false);
          }
        })
        .fail((jqXHR, status, error) => {
          $('#essentials-error-body').html(error);
          $('#essentials-error-alert').slideDown(100);
          $('#essentials-submit').removeClass('btn-waiting').prop('disabled', false);
        });
    });

    $('#dsa-form').on('submit', (event) => {
      event.preventDefault();

      if ($('#dsa-form').valid()) {
        $('#dsa-error-alert').hide();
        $('#dsa-submit').addClass('btn-waiting').prop('disabled', true);
        $.ajax({
          url: `/campaign/edit/process?project_id=${projectID}&campaign=${campaignID}`, type: 'get', data: $(event.currentTarget).serialize(), dataType: 'json',
        })
          .done((data) => {
            if (data.success) {
              $('#dsa-warning-alert').hide();

              $('#dsa-submit').removeClass('btn-waiting').prop('disabled', false);
              $('#dsa-success-alert').fadeTo(2000, 500).slideDown(100, () => {
                $('#dsa-success-alert').slideUp(500);
              });
            } else {
              $('#dsa-error-body').html(data.message);
              $('#dsa-error-alert').slideDown(100);
              $('#dsa-submit').removeClass('btn-waiting').prop('disabled', false);
            }
          })
          .fail((jqXHR, status, error) => {
            $('#dsa-error-body').html(error);
            $('#dsa-error-alert').slideDown(100);
            $('#dsa-submit').removeClass('btn-waiting').prop('disabled', false);
          });
      }
    });

    /** EVENT TRIGGER HANDLERS -- END */
  });
};

export default { init };
